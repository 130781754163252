import React, { useContext } from "react";
import Layout from "../../../components/Layout/layout";
import TopCompanyGerman from "../../../components/Blog-Detail/topTaxiCompanies/german-blog";
import SEO from "../../../components/SEO/seo";
const TopCompany = () => {
	return (
		<Layout>
			<SEO
				title="Top 8 Taxiunternehmen in Hamburg, Deutschland - Sie sollten buchen"
				meta="Online Taxi Neumünster to Kiel | Taxi neumünster to Hamburg"
				description="In diesem Blog haben wir mehrere Taxiunternehmen engagiert, die Ihre Fahrt komfortabel machen. Einige Unternehmen bieten auch die Möglichkeit, die Kabine vorzubuchen."
			/>
			<TopCompanyGerman />
		</Layout>
	);
};

export default TopCompany;
